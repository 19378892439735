@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./assets/font/Nunito/Nunito-Regular.ttf) format('ttf'); 
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Outfit';
  src: local('Outfit'), url(./assets/font/Outfit/Outfit-Regular.ttf) format('ttf'); 
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}




@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.active {
    color: "#FFAE0A",;
}



body, html {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
}

.heading-text {
  font-family: 'Outfit', sans-serif;
}

/* index.css */
:root {
  --underline-color: #FFAE0A; /* Set your desired underline color here */
}

.link:hover,
.link:active {
  border-bottom: 2px solid var(--underline-color);
  transition: border-color 0.3s ease;
}

::-webkit-scrollbar {
  width: 4px; /* Set your desired scrollbar width */
}
